import { forwardRef, HTMLAttributes } from 'react'
import classNames from 'classnames'

import type { SectionTheme } from '@/types'

type SectionProps = SectionTheme & HTMLAttributes<HTMLElement>

const Section = forwardRef<HTMLElement, SectionProps>(
  ({ theme = 'light', className, children, ...props }, ref) => {
    return (
      <section
        ref={ref}
        className={classNames(
          { dark: theme === 'dark' },
          'relative z-section w-full bg-background-primary-light dark:bg-background-primary-dark',
          className
        )}
        {...props}
      >
        {children}
      </section>
    )
  }
)

export default Section
