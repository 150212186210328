export type Media = {
  type: 'image' | 'video' | 'hdr' | 'glb'
  src: string
}

export const precacheMedia = async (
  sourceList: Media[],
  // eslint-disable-next-line no-unused-vars
  cb = (res: any) => {}
) => {
  const images = sourceList?.filter((src) => src.type === 'image')
  const videos = sourceList?.filter(
    (src) => src.type === 'video' || src.type === 'hdr' || src.type === 'glb'
  )

  const promisesImage = images.map((item) => {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.src = item.src
      img.onload = () =>
        resolve({
          ...item,
          srcCached: item.src
        })
      img.onerror = () => reject()
    })
  })

  const promisesVideo = videos.map((item) => {
    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest()
      req.open('GET', item.src, true)
      req.responseType = 'blob'
      req.onload = () => {
        if (req.status === 200) {
          const videoBlob = req.response
          const video = URL.createObjectURL(videoBlob)
          resolve({
            ...item,
            srcCached: video
          })
        }
      }
      req.onerror = () => reject()
      req.send()
    })
  })

  await Promise.all([...promisesImage, ...promisesVideo]).then((res) => cb(res))
}
