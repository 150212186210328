/* eslint-disable @next/next/no-html-link-for-pages */
import classNames from 'classnames'

import ButtonIcon from '@/components/base/ButtonIcon'
import { ContainerFluid } from '@/components/base/Container'
import Grid from '@/components/base/Grid'
import Link from '@/components/base/LinkRouter'
import SVGIcon from '@/components/base/SVGIcon'
import Typography from '@/components/base/Typography'

import { useStore } from '@/contexts/store'

const Footer = () => {
  const { menu, strings } = useStore()

  return (
    <footer className="dark bg-background-primary-dark py-spacing-lg md:py-spacing-2xl">
      <ContainerFluid>
        <Grid className="gap-y-spacing-lg md:gap-y-spacing-2xl">
          <div className="col-span-2 md:col-span-6 lg:col-span-12">
            <div className="grid grid-cols-2 gap-x-gutter gap-y-spacing-lg md:grid-cols-6 md:gap-y-spacing-2xl lg:grid-cols-12">
              <div className="col-span-2 flex justify-center md:col-span-6 lg:order-2 lg:col-span-6 lg:justify-end">
                <SVGIcon
                  symbol="large-logo-icon"
                  className="h-[9.2rem] w-[15.4rem] md:h-[13.2rem] md:w-[22rem]"
                />
              </div>

              <div className="col-span-2 md:col-span-6 lg:order-1 lg:col-span-6">
                <div className="grid grid-cols-2 gap-x-gutter gap-y-spacing-lg md:grid-cols-6 lg:grid-cols-6">
                  <nav className="col-span-2">
                    <ul className="flex flex-col space-y-spacing-xs md:space-y-spacing-sm">
                      {menu?.footerMenu01?.menu.map(
                        ({ title, url, children }, index) =>
                          children.length > 0 ? (
                            children.map(({ title, url }, index) => (
                              <li key={`menu-1-${index}`} className="flex">
                                <Link
                                  href={url}
                                  className="-m-spacing-xxs flex p-spacing-xxs"
                                >
                                  <Typography
                                    variant="regular"
                                    className="text-text-primary-light dark:text-text-primary-dark"
                                  >
                                    {title}
                                  </Typography>
                                </Link>
                              </li>
                            ))
                          ) : (
                            <li key={`menu-1-${index}`} className="flex">
                              <Link
                                href={url}
                                className="-m-spacing-xxs flex p-spacing-xxs"
                              >
                                <Typography
                                  variant="regular"
                                  className="text-text-primary-light dark:text-text-primary-dark"
                                >
                                  {title}
                                </Typography>
                              </Link>
                            </li>
                          )
                      )}
                    </ul>
                  </nav>
                  <nav className="col-span-2">
                    <ul className="flex flex-col space-y-spacing-xs md:space-y-spacing-sm">
                      {menu?.footerMenu02?.menu.map(({ title, url }, index) => (
                        <li key={`menu-2-${index}`} className="flex">
                          <Link
                            href={url}
                            className="-m-spacing-xxs flex p-spacing-xxs"
                          >
                            <Typography
                              variant="small"
                              className="text-text-primary-light dark:text-text-primary-dark"
                            >
                              {title}
                            </Typography>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </nav>
                  {strings?.footerBtn?.link.url ? (
                    <div className="col-span-2">
                      <ButtonIcon
                        href={strings?.footerBtn?.link.url}
                        icon={strings?.footerBtn?.icon}
                      >
                        {strings?.footerBtn?.link.title}
                      </ButtonIcon>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="col-span-2 md:col-span-6 lg:col-span-12">
            <div className="grid gap-y-spacing-sm md:gap-y-spacing-lg">
              <div className="grid gap-y-spacing-sm">
                <Typography
                  variant="small"
                  className="text-text-primary-light dark:text-text-primary-dark"
                >
                  {strings?.memberOf}
                </Typography>
                <ul className="-mb-spacing-xs flex flex-wrap [&>li:last-child]:mr-0 [&>li]:mb-spacing-xs [&>li]:mr-spacing-xs md:[&>li]:mr-spacing-lg">
                  {menu?.logosMenu?.menu.map(
                    ({ title, url, description }, index) => (
                      <li key={`menu-5-${index}`} className="flex">
                        <a href={url} target="_blank" aria-label={title}>
                          <img
                            src={description}
                            alt=""
                            className="h-[8rem] w-auto md:h-[10rem]"
                            // className="h-auto w-[14.2rem] md:w-[17.8rem]"
                          />
                        </a>
                      </li>
                    )
                  )}
                </ul>
              </div>
              <div className="grid gap-y-spacing-sm">
                <div className="flex flex-col flex-wrap space-y-spacing-xs xl:flex-row xl:space-x-spacing-sm xl:space-y-0">
                  <ul className="flex flex-col flex-wrap space-y-spacing-xs md:flex-row md:space-x-spacing-sm md:space-y-0">
                    {menu?.footerMenu04?.menu.map(({ title, url }, index) => (
                      <li key={`menu-4-${index}`} className="whitespace-nowrap">
                        <Link
                          href={url}
                          className="-m-spacing-xxs flex p-spacing-xxs"
                        >
                          <Typography
                            variant="small"
                            className="text-text-secondary-light dark:text-text-secondary-dark"
                          >
                            {title}
                          </Typography>
                        </Link>
                      </li>
                    ))}
                  </ul>
                  <ul className="flex flex-col flex-wrap space-y-spacing-xs md:flex-row md:space-x-spacing-sm md:space-y-0">
                    {menu?.footerMenu03?.menu.map(({ title, url }, index) => (
                      <li key={`menu-4-${index}`} className="whitespace-nowrap">
                        <Link
                          href={url}
                          className="-m-spacing-xxs flex p-spacing-xxs"
                        >
                          <Typography
                            variant="small"
                            className="text-text-secondary-light dark:text-text-secondary-dark"
                          >
                            {title}
                          </Typography>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="flex flex-col space-y-spacing-lg md:flex-row md:items-end md:justify-between md:space-x-spacing-sm md:space-y-0">
                  <Typography
                    variant="small"
                    className="text-text-primary-light dark:text-text-primary-dark"
                  >
                    {strings?.footerDisclaimer.replace(
                      '[YEARCOPY]',
                      new Date().getFullYear()
                    )}
                  </Typography>

                  <ul className="flex items-center space-x-spacing-xs">
                    {menu?.socialMenu?.menu.map(({ title, url, classes }) => (
                      <li key={title} className="-mb-[.25rem]">
                        <a
                          href={url}
                          className={classNames(
                            '-m-spacing-xxs flex p-spacing-xxs',
                            'transition-opacity duration-300 ease-out-cubic pointer:hover:opacity-40'
                          )}
                          target="_blank"
                          aria-label={title}
                        >
                          <span className="flex h-[1.6rem] w-[1.6rem] items-center justify-center">
                            <SVGIcon
                              symbol={`${classes}-icon`}
                              className={classNames(
                                'fill-text-primary-light dark:fill-text-primary-dark',
                                {
                                  'h-[1.2rem] w-[0.7rem]':
                                    classes === 'facebook',
                                  'h-[1.5rem] w-[1.4rem]':
                                    classes === 'instagram',
                                  'h-[1.2rem] w-[1.2rem]':
                                    classes === 'linkedin',
                                  'h-[1.6rem] w-[1.6rem]':
                                    classes === 'youtube',
                                  'h-[1.3rem] w-[1.4rem]': classes === 'twitter'
                                }
                              )}
                            />
                          </span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </ContainerFluid>
    </footer>
  )
}

export default Footer
