import { PropsWithChildren } from 'react'
import { useIsomorphicLayoutEffect } from 'react-use'

import Footer from '@/components/layout/Footer'
import useChangePathname from '@/hooks/useChangePathname'
import { useLocaleStore } from '@/store/locale'

import { LenisProvider } from '@/contexts/lenis'
import { WPMLTranslations } from '@/types'

type LayoutProps = PropsWithChildren<{
  translations?: WPMLTranslations
}>

const Layout = ({ translations, children }: LayoutProps) => {
  const setCurrentTranslations = useLocaleStore(
    (store) => store.setCurrentTranslations
  )

  useIsomorphicLayoutEffect(() => {
    setCurrentTranslations(translations || [])
  }, [translations])

  useChangePathname()

  return (
    <LenisProvider>
      {children}
      <Footer />
    </LenisProvider>
  )
}

export default Layout
