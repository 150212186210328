import { useEffect } from 'react'
import { useRouter } from 'next/router'

import { useLocaleStore } from '@/store/locale'
import { useRouterStore } from '@/store/router'

const useChangePathname = () => {
  const router = useRouter()

  const setCurrentRoute = useRouterStore((state) => state.setCurrentRoute)
  const setCurrentLocale = useLocaleStore((state) => state.setCurrentLocale)

  useEffect(() => {
    setCurrentRoute(router.asPath)
    setCurrentLocale(router.locale)
  }, [router.asPath, router.locale, setCurrentRoute, setCurrentLocale])
}

export default useChangePathname
