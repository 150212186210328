import { useCallback, useEffect } from 'react'

import { useLoaderStore } from '@/store/loader'
import { removeUndefined } from '@/utils/array'
import { Media, precacheMedia } from '@/utils/media'

const usePagePrecache = (init = false, sources: Media[] = []) => {
  const setLoaderState = useLoaderStore((state) => state.setState)

  const completePreload = useCallback(() => {
    setLoaderState({ pageLoaded: true })
  }, [setLoaderState])

  /*------------------------------
  Start the media precache
  ------------------------------*/
  useEffect(() => {
    if (init && removeUndefined(sources).length > 0) {
      precacheMedia(
        removeUndefined(sources), // source list
        completePreload // callback
      )
    }
    if (init && removeUndefined(sources).length === 0) completePreload()
  }, [init, sources, completePreload])
}

export default usePagePrecache
