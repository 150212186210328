import parse from 'html-react-parser'
import Head from 'next/head'

import { WPMLTranslations, WPPageData } from '@/types'

type MetaProps = {
  pageData: WPPageData<{
    yoast_head: string
    wpml_translations: WPMLTranslations
    xdefault_translation: WPMLTranslations
  }>
}

const Meta = ({ pageData }: MetaProps) => {
  return pageData?.yoast_head ? (
    <Head>
      {parse(
        pageData.yoast_head.replace(
          /"\//g,
          `"${process.env.NEXT_PUBLIC_FRONT_BASE_URL}/`
        )
      )}
      {pageData?.wpml_translations?.length > 0
        ? pageData.wpml_translations.map((translation, index) => (
            <link
              key={`${translation.id}${index}`}
              rel="alternate"
              hrefLang={translation.code}
              href={`${process.env.NEXT_PUBLIC_FRONT_BASE_URL}${translation.href}`}
            />
          ))
        : null}
      {pageData?.xdefault_translation?.length > 0
        ? pageData.xdefault_translation.map((translation, index) => (
            <link
              key={`${translation.id}${index}`}
              rel="alternate"
              hrefLang="x-default"
              href={`${process.env.NEXT_PUBLIC_FRONT_BASE_URL}${translation.href}`}
            />
          ))
        : null}
    </Head>
  ) : null
}

export default Meta
